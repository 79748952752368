export const setError = (state, payload) => {
    state.dataError = payload;
};

export const setListPromoCode = (state, payload) => {
    state.promoCodeList = payload;
};

export const setItemPromoCode = (state, payload) => {
    state.promoCodeItem = payload;
};