<template>
<main-view/>
</template>

<script>
import MainView from "@/views/MainView.vue";

export default {
  name:"App",
  components: {MainView},
}

</script>

